<template>
  <div>
    <div
      v-if="media.type == 'youtube' || media.type == 'vimeo'"
      id="pl"
      :data-plyr-provider="media.type"
      :data-plyr-embed-id="media.playerId"
    ></div>
  </div>
</template>

<script>
import Plyr from 'plyr'

export default {
  props: {
    media: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    /** Options object for plyr config. **/
    options: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    /** Array of events to emit from the plyr object **/
    emit: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      player: {},
      showAudioDialog: false
    }
  },
  computed: {
    opts() {
      const options = this.options
      if (!this.options.hasOwnProperty('hideYouTubeDOMError')) {
        options.hideYouTubeDOMError = true
      }
      return options
    }
  },

  mounted() {
    this.player = new Plyr(this.$el.firstChild, this.opts)
    this.emit.forEach((element) => {
      this.player.on(element, this.emitPlayerEvent)
    })
  },
  beforeDestroy() {
    try {
      this.player.destroy()
      this.player = undefined
    } catch (e) {
      if (
        !(
          this.opts.hideYouTubeDOMError &&
          e.message === 'The YouTube player is not attached to the DOM.'
        )
      ) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  },
  methods: {
    emitPlayerEvent(event) {
      this.$emit(event.type, event)
    }
  },
  created() {
    // eventBus.$on('mediaSelected',(media)=>{
    //     this.media=media;
    // });
  },
  updated() {
    // this.player = new Plyr(this.$el.firstChild, this.opts)
    // this.emit.forEach((element) => {
    //   this.player.on(element, this.emitPlayerEvent)
    // })
  }
}
</script>

<style src="../../node_modules/plyr/dist/plyr.css"></style>
<style>
.plyr {
  border-radius: 15px;
  --plyr-color-main: var(--v-primary-base) !important;
}
</style>
