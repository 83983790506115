import { render, staticRenderFns } from "./Player.vue?vue&type=template&id=5f112621"
import script from "./Player.vue?vue&type=script&lang=js"
export * from "./Player.vue?vue&type=script&lang=js"
import style0 from "../../node_modules/plyr/dist/plyr.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Player.vue?vue&type=style&index=1&id=5f112621&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports